import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Admin_errorDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const admin_error_buttons_positiveOnClick = (options) => {
		run([{"function":"hide","dialog":"admin_error"}], options)
	}

  return (
    
    
			<Modal id="admin_error" centered>
					<Modal.Header>
						<Modal.Title>{t('admin_error.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Text
							id="text1680030975660"
							className="w-100 h-auto position-relative" >
							{t('admin_error.text1680030975660')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="admin_error_buttons_positive"
							onClick={admin_error_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('admin_error.admin_error_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Admin_errorDialog;
