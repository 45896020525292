import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Auth_fullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text } from '../components';
import { useEffect } from 'react';
import icon_192 from '../assets/images/icon_192.png';
import { useTranslation } from 'react-i18next';

export const PrivacyView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const privacyParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( privacyParams, [])
	const privacyOnLoaded = (options) => {
		run([{"id":"wait1700787222677","function":"wait","seconds":2},{"id":"set1700786057410","function":"set","what":"@cookie.lang","value":"@property.lang"},{"id":"set1700786670872","function":"set","what":"@property.iso","value":"en"},{"id":"set1700786688215","function":"set","what":"@property.iso","value":"es","if":{"what":"@cookie.lang","is":"es"}},{"id":"set1700786705995","function":"set","what":"@property.iso","value":"ca","if":{"what":"@cookie.lang","is":"ca"}},{"id":"set1700786716980","function":"set","what":"@property.iso","value":"fr","if":{"what":"@cookie.lang","is":"fr"}},{"id":"set1700786729532","function":"set","what":"@property.iso","value":"pt","if":{"what":"@cookie.lang","is":"pt"}},{"id":"set1700786753503","function":"set","what":"@property.iso","value":"it","if":{"what":"@cookie.lang","is":"it"}},{"id":"set1700783906224","function":"set","what":"@app.language","value":"@property.iso"}], options)
	}
	useEffect(privacyOnLoaded, [])

  return (
    <>
      <Helmet>
				<title>{t('privacy.title')}</title>
      </Helmet>
      <Auth_fullscreenWrapper>
          
			<Group
				id="privacyBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative p-24px" >
				<Group
					id="privacyWrapper"
					direction="vertical"
					className="max-w-1000px w-100 h-auto align-center position-relative p-32px mt-24px" >
					<Image
						id="policyLogo"
						src={icon_192}
						className="w-156px h-auto border-radius-24px align-center position-relative" />
					<Text
						id="policyTitle"
						tag="h2"
						className="w-auto h-auto align-center valign-center position-relative mt-16px text-primary-dark ff-auth_bold" >
						{t('privacy.policyTitle')}
					</Text>
					<Text
						id="policySubtitle"
						tag="h1"
						className="w-auto h-auto align-center valign-center position-relative mt-16px text-primary ff-auth_bold" >
						{t('privacy.policySubtitle')}
					</Text>
					<Group
						id="policyContentWrapper"
						direction="vertical"
						className="w-100 h-auto position-relative mt-48px" >
						<Text
							id="signinTitle1682289395892"
							tag="h3"
							className="w-auto h-auto align-center valign-center position-relative mt-16px text-primary-text ff-gantariregular" >
							{t('privacy.signinTitle1682289395892')}
						</Text>
					</Group>
				</Group>
			</Group>
      </Auth_fullscreenWrapper>
    </>
  );
};
