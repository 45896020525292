import { i18next } from './IntlProvider';

const languages = [
	{
		code: 'xx',
		text: '',
	},
	{
		code: 'es',
		text: 'Español',
	},
	{
		code: 'it',
		text: 'Italiano',
	},
	{
		code: 'fr',
		text: 'Français',
	},
	{
		code: 'ca',
		text: 'Català',
	},
	{
		code: 'pt',
		text: 'Português',
	},
	{
		code: 'de',
		text: 'Deutsch',
	},
];

export { i18next as i18n, languages };
