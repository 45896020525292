import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Auth_fullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Input, Lottie } from '../components';
import { useEffect } from 'react';
import auth_progress from '../assets/animations/auth_progress.json';
import icon_192 from '../assets/images/icon_192.png';
import { useTranslation } from 'react-i18next';

export const AdminView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const adminParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( adminParams, [])
	const adminOnLoaded = (options) => {
		run([{"id":"set1680030700314","function":"set","what":"@property.showCurtain","value":false},{"function":"set","what":"@property.url","value":"(@property.redirect_uri.urldecode)?client_id=(@property.client_id)&code=(@property.token)&state=(@property.state)"}], options)
	}
	useEffect(adminOnLoaded, [])
	const loginButtonOnClick = (options) => {
		run([{"id":"set1680030700314","function":"set","what":"@property.showCurtain","value":true},{"function":"login","provider":"firebase.email","email":"@property.authEmail","pass":"@property.authPassword","onvalid":[{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"id":"do1680030863376","function":"do","if":{"what":"@firebase.firestore.users.(@cookie.userID).admin","is":true,"else":[{"id":"logout1680030929958","function":"logout"},{"id":"popup1680031054565","function":"popup","dialog":"admin_error"}]},"what":[{"id":"goto1680031130648","function":"goto","view":"admin_home"}]}],"oninvalid":[{"id":"set1680030700314","function":"set","what":"@property.showCurtain","value":false},{"function":"set","what":"@property.authMessage","value":"signin"},{"function":"popup","dialog":"auth_dialog"}]}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('admin.title')}</title>
      </Helmet>
      <Auth_fullscreenWrapper>
          
			<Group
				id="authBody1680030700314"
				direction="vertical"
				className="w-100 min-h-100vh h-100vh position-relative" >
				<Group
					id="authWrapper1680030700314"
					direction="horizontal"
					className="min-w-320px w-fill min-h-100 fg-100 position-relative py-32px" >
					<Group
						id="authArea1680030700314"
						direction="vertical"
						className="w-100 h-auto valign-center position-relative" >
						<Group
							id="authForms1680030700314"
							direction="vertical"
							className="max-w-400px w-fill h-auto align-center valign-center position-absolute" >
							<Group
								id="signinForm1680030700314"
								if={{"what":"@property.authForm","is_not":"signup"}}
								direction="vertical"
								className="w-100 h-auto border-radius-15px position-relative p-32px mt-24px" >
								<Image
									id="image1680030700314"
									src={icon_192}
									className="w-30 h-auto border-radius-24px align-center position-relative" />
								<Text
									id="signinTitle1680030700314"
									tag="h1"
									className="w-auto h-auto align-center valign-center position-relative mt-16px text-primary-text ff-auth_bold" >
									{t('admin.signinTitle1680030700314')}
								</Text>
								<Group
									id="authSigninEmail1680030700314"
									direction="vertical"
									className="w-100 h-auto position-relative mt-24px" >
									<Input.Email
										id="authLoginEmailInput1680030700314"
										name="loginEmail"
										src="@property.authEmail"
									placeholder={t('admin.authLoginEmailInput1680030700314.hint')}
									autoComplete="off"
										className="w-100 h-auto position-relative bg-primary-light" />
									<Input.Password
										id="authLoginPasswordInput1680030700314"
										name="loginEmail"
										src="@property.authPassword"
									placeholder={t('admin.authLoginPasswordInput1680030700314.hint')}
									autoComplete="off"
										className="w-100 h-auto position-relative bg-primary-light mt-24px" />
								</Group>
								<Text
									id="loginButton"
									onClick={loginButtonOnClick}
									className="w-100 h-auto border-radius-10px position-relative bg-primary-dark py-8px mt-32px text-text-on-primary ff-auth_regular text-center" >
									{t('admin.loginButton')}
								</Text>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
			<Group
				id="loginCurtain"
				if={{"what":"@property.showCurtain","is":true}}
				direction="vertical"
				className="w-100 min-h-100vh h-100vh position-absolute bg-oauth_lighten" >
				<Lottie
					id="loginLottie"
					src={auth_progress}
					className="w-150px min-h-150px h-150px align-center valign-center position-absolute" />
			</Group>
      </Auth_fullscreenWrapper>
    </>
  );
};
