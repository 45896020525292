import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Auth_fullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Icon, List } from '../components';
import { useEffect } from 'react';
import icon_192 from '../assets/images/icon_192.png';
import { useTranslation } from 'react-i18next';

export const Admin_homeView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const admin_homeParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( admin_homeParams, [])
	const admin_homeOnLoaded = (options) => {
		run([{"id":"do1681294282008","function":"do","what":[{"id":"set1681294296394","function":"set","what":"@property.section","value":"hubs"},{"id":"set1681294296394","function":"set","what":"@cookie.title","value":"Hubs"},{"id":"clone1681294296394","function":"clone","what":"@firebase.firestore.hubs","into":"@database.hubs"},{"id":"do1681294296394","function":"do"}],"if":{"what":"@firebase.firestore.users.(@firebase.user.id).admin","is":true,"else":[{"id":"logout1681294364830","function":"logout"}]}}], options)
	}
	useEffect(admin_homeOnLoaded, [])
	const hubIDLabel1681292243554OnClick = (options) => {
		run([{"id":"set1681292445089","function":"set","what":"@property.action","value":"unlink_hub"},{"id":"set1681292474432","function":"set","what":"@property.hubID","value":"@element.hubList.selected"},{"id":"set1681293244019","function":"set","what":"@cookie.hub","value":"@element.hubList.value"},{"id":"popup1681293232605","function":"popup","dialog":"confirmation"}], options)
	}
	const menubuttonOnClick = (options) => {
		run([{"id":"set1680079967505","function":"set","what":"@property.menuOpen","value":true,"if":{"what":"@property.menuOpen","is":false,"else":[{"id":"set1680080004246","function":"set","what":"@property.menuOpen","value":false}]}}], options)
	}
	const optionHubsOnClick = (options) => {
		run([{"id":"set1681292040238","function":"set","what":"@property.section","value":"hubs"},{"id":"set1681292053586","function":"set","what":"@cookie.title","value":"Hubs"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('admin_home.title')}</title>
      </Helmet>
      <Auth_fullscreenWrapper>
          
			<Group
				id="adminBody"
				direction="horizontal"
				className="w-100 min-h-100 fg-100 position-relative" >
				<Group
					id="group1680031204480"
					direction="vertical"
					className="w-auto min-h-100 h-100 position-relative bg-primary-dark p-16px" >
					<Image
						id="image1680031450479"
						src={icon_192}
						className="w-48px h-auto position-relative mt-32px" />
					<Text
						id="text1680031681067"
						if={{"what":"@property.menuOpen","is":true}}
						tag="h2"
						className="w-fill h-auto position-relative text-primary ff-renner" >
						{t('admin_home.text1680031681067')}
					</Text>
					<Group
						id="menuOptions"
						direction="vertical"
						className="w-auto h-auto position-relative mt-24px" >
						<Group
							id="optionHubs"
							onClick={optionHubsOnClick}
							direction="horizontal"
							className="w-auto h-auto position-relative" >
							<Group
								id="group1680031958611"
								if={{"what":"@property.section","is":"hubs"}}
								direction="vertical"
								className="w-100 min-h-100 h-100 border-primary border-top border-bottom border-start border-end position-absolute" >
							</Group>
							<Group
								id="group1680032032770"
								direction="horizontal"
								className="w-auto h-auto position-relative p-8px" >
								<Icon
									id="icon1680031541466"
									src="router"
									color="var(--primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="text1680031566511"
									if={{"what":"@property.menuOpen","is":true}}
									className="w-auto h-auto position-relative ps-16px text-primary ff-renner" >
									{t('admin_home.text1680031566511')}
								</Text>
							</Group>
						</Group>
					</Group>
					<Group
						id="menuOptions1680080177031"
						direction="vertical"
						className="w-auto h-auto position-relative mt-24px" >
						<Group
							id="optionHubs1680080177031"
							direction="horizontal"
							className="w-auto h-auto position-relative" >
							<Group
								id="group1680080177031"
								if={{"what":"@property.section","is":"hubs"}}
								direction="vertical"
								className="w-100 min-h-100 h-100 border-primary border-top border-bottom border-start border-end position-absolute" >
							</Group>
							<Group
								id="group1680080177031"
								direction="horizontal"
								className="w-auto h-auto position-relative p-8px" >
								<Icon
									id="icon1680080177031"
									src="account"
									color="var(--primary)"
									className="w-auto h-auto position-relative"
								/>
								<Text
									id="text1680080177031"
									if={{"what":"@property.menuOpen","is":true}}
									className="w-auto h-auto position-relative ps-16px text-primary ff-renner" >
									{t('admin_home.text1680080177031')}
								</Text>
							</Group>
						</Group>
					</Group>
					<Icon
						id="menubutton"
						onClick={menubuttonOnClick}
						src="menu"
						size={3}
						color="var(--primary)"
						className="w-auto h-auto align-start valign-start position-absolute p-6px"
					/>
				</Group>
				<Group
					id="workArea"
					direction="vertical"
					className="w-fill min-h-100 h-100 position-relative" >
					<Group
						id="headerBar"
						direction="vertical"
						className="w-100 h-auto position-relative bg-primary-light p-16px" >
						<Text
							id="text1681291904957"
							className="w-100 h-auto position-relative text-primary-text" >
							{t('admin_home.text1681291904957')}
						</Text>
					</Group>
					<Group
						id="hubArea"
						if={{"what":"@property.section","is":"hubs"}}
						direction="vertical"
						className="w-100 h-fill position-relative p-24px scrollable" >
						<List
							id="hubList"
							src="@database.hubs"
							className="w-100 h-auto position-relative" >
							<Group
								id="hubRow"
								direction="horizontal"
								className="w-100 h-auto border-bottom position-relative p-6px" >
								<Text
									id="hubIDLabel"
									className="w-auto h-auto position-relative" >
									{t('admin_home.hubIDLabel')}
								</Text>
								<Group
									id="hubRowSpacer"
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
								<Group
									id="hubRowFamilyGroup"
									direction="vertical"
									className="w-auto h-auto position-relative" >
									<Text
										id="hubRowFamily"
										if={{"what":"@field.family_id","is_not":null}}
										className="w-auto h-auto position-relative" >
										{t('admin_home.hubRowFamily')}
									</Text>
									<Text
										id="hubIDLabel1681292243554"
										if={{"what":"@field.family_id","is_not":null}}
										onClick={hubIDLabel1681292243554OnClick}
										className="w-auto h-auto align-end valign-center position-relative ms-8px text-primary-light f-12px" >
										{t('admin_home.hubIDLabel1681292243554')}
									</Text>
								</Group>
							</Group>
						</List>
					</Group>
				</Group>
			</Group>
      </Auth_fullscreenWrapper>
    </>
  );
};
