import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Auth_fullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Input, Lottie } from '../components';
import { useEffect } from 'react';
import auth_progress from '../assets/animations/auth_progress.json';
import icon_192 from '../assets/images/icon_192.png';
import { useTranslation } from 'react-i18next';

export const OauthView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const oauthParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( oauthParams, [])
	const oauthOnLoaded = (options) => {
		run([{"id":"set1686912918944","function":"set","what":"@property.showCurtain","value":false},{"function":"set","what":"@property.url","value":"(@property.redirect_uri.urldecode)?client_id=(@property.client_id)&code=(@property.token)&state=(@property.state)"}], options)
	}
	useEffect(oauthOnLoaded, [])
	const loginButtonGoogle1672245892787OnClick = (options) => {
		run([{"id":"popup1672245949470","function":"popup","dialog":"instructions"}], options)
	}
	const loginButtonGoogleOnClick = (options) => {
		run([{"id":"set1672229604455","function":"set","what":"@property.showCurtain","value":true},{"function":"login","provider":"firebase.email","email":"@property.authEmail","pass":"@property.authPassword","onvalid":[{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"function":"set","what":"@property.token","value":"@firebase.user.id"},{"function":"set","what":"@property.url","value":"(@property.redirect_uri.urldecode)?client_id=(@property.client_id)&code=(@property.token)&state=(@property.state)"},{"id":"add1672229604455","function":"add","what":{"token":"@cookie.userID","user_id":"@cookie.userID"},"to":"@firebase.firestore.assistants.(@property.client_id).tokens","key":"@firebase.user.id"},{"id":"browser1672229604455","function":"browser","url":"@property.url"}],"oninvalid":[{"id":"set1672229604455","function":"set","what":"@property.showCurtain","value":false},{"function":"set","what":"@property.authMessage","value":"signin"},{"function":"popup","dialog":"auth_dialog"}]}], options)
	}
	const loginButtonAlexaOnClick = (options) => {
		run([{"id":"set1666653235061","function":"set","what":"@property.showCurtain","value":true},{"function":"login","provider":"firebase.email","email":"@property.authEmail","pass":"@property.authPassword","onvalid":[{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"function":"set","what":"@property.token","value":"@firebase.user.id"},{"function":"set","what":"@property.url","value":"(@property.redirect_uri.urldecode)?client_id=(@property.client_id)&code=(@property.token)&state=(@property.state)"},{"id":"add1666772171610","function":"add","what":{"token":"@cookie.userID","user_id":"@cookie.userID"},"to":"@firebase.firestore.assistants.(@property.client_id).tokens","key":"@firebase.user.id"},{"function":"set","what":"@firebase.firestore.assistants.(@property.client_id).tokens.abcde.token","value":"@cookie.userID"},{"id":"browser1666772118628","function":"browser","url":"@property.url"}],"oninvalid":[{"id":"set1666653254887","function":"set","what":"@property.showCurtain","value":false},{"function":"set","what":"@property.authMessage","value":"signin"},{"function":"popup","dialog":"auth_dialog"}]}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('oauth.title')}</title>
      </Helmet>
      <Auth_fullscreenWrapper>
          
			<Group
				id="authBody"
				direction="vertical"
				className="w-100 min-h-100vh h-100vh position-relative" >
				<Group
					id="authWrapper"
					direction="vertical"
					className="min-w-320px w-fill min-h-100 fg-100 position-relative" >
					<Group
						id="authArea"
						direction="vertical"
						className="w-100 h-fill valign-start position-relative" >
						<Group
							id="authForms"
							direction="vertical"
							className="max-w-400px w-fill h-auto align-center valign-center position-absolute" >
							<Group
								id="signinForm"
								if={{"what":"@property.authForm","is_not":"signup"}}
								direction="vertical"
								className="w-100 h-auto border-radius-15px position-relative p-32px mt-24px" >
								<Image
									id="image1672230167420"
									src={icon_192}
									className="w-30 h-auto border-radius-24px align-center position-relative" />
								<Text
									id="signinTitle"
									tag="h1"
									className="w-auto h-auto align-center valign-center position-relative mt-16px text-primary-text ff-auth_bold" >
									{t('oauth.signinTitle')}
								</Text>
								<Group
									id="authSigninEmail"
									direction="vertical"
									className="w-100 h-auto position-relative mt-24px" >
									<Input.Email
										id="authLoginEmailInput"
										name="loginEmail"
										src="@property.authEmail"
									placeholder={t('oauth.authLoginEmailInput.hint')}
									autoComplete="off"
										className="w-100 h-auto position-relative bg-primary-light" />
									<Input.Password
										id="authLoginPasswordInput"
										name="loginEmail"
										src="@property.authPassword"
									placeholder={t('oauth.authLoginPasswordInput.hint')}
									autoComplete="off"
										className="w-100 h-auto position-relative bg-primary-light mt-24px" />
								</Group>
								<Text
									id="loginButtonAlexa"
									if={{"what":"@property.client_id","is":"idemoappalexa"}}
									onClick={loginButtonAlexaOnClick}
									className="w-100 h-auto border-radius-10px position-relative bg-primary-dark py-8px mt-32px text-text-on-primary ff-auth_regular text-center" >
									{t('oauth.loginButtonAlexa')}
								</Text>
								<Group
									id="group1687768255426"
									if={{"what":"@property.client_id","is":"idehomeactions"}}
									direction="vertical"
									className="w-100 h-auto position-relative" >
									<Text
										id="loginButtonGoogle"
										onClick={loginButtonGoogleOnClick}
										className="w-100 h-auto border-radius-10px position-relative bg-primary-dark py-8px mt-32px text-text-on-primary ff-auth_regular text-center" >
										{t('oauth.loginButtonGoogle')}
									</Text>
									<Text
										id="googleStatement"
										className="w-100 h-auto border-radius-10px position-relative py-8px mt-8px text-secondary-text ff-auth_regular text-center" >
										{t('oauth.googleStatement')}
									</Text>
								</Group>
								<Text
									id="loginButtonGoogle1672245892787"
									onClick={loginButtonGoogle1672245892787OnClick}
									className="w-100 h-auto border-radius-10px position-relative py-8px mt-32px text-primary-dark ff-auth_regular text-center" >
									{t('oauth.loginButtonGoogle1672245892787')}
								</Text>
							</Group>
						</Group>
					</Group>
					<Group
						id="authFooter"
						direction="vertical"
						className="w-100 h-auto valign-end position-relative bg-accent p-16px" >
						<Text
							id="copyright"
							className="w-100 h-auto position-relative text-text-on-primary text-center" >
							{t('oauth.copyright')}
						</Text>
					</Group>
				</Group>
			</Group>
			<Group
				id="authCurtain"
				if={{"what":"@property.showCurtain","is":true}}
				direction="vertical"
				className="w-100 min-h-100vh h-100vh position-absolute bg-oauth_lighten" >
				<Lottie
					id="oauthLottie"
					src={auth_progress}
					className="w-150px min-h-150px h-150px align-center valign-center position-absolute" />
			</Group>
      </Auth_fullscreenWrapper>
    </>
  );
};
