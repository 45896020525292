import React, { useEffect, useRef, useState } from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Component from './Component';
import Label from './Label';
import { useFunctions, useSource } from '../hooks';
import { parse } from '../util';

const InputCheckbox = (props) => {
  return <FormCheck {...props} type="checkbox" />;
};
const InputSwitch = (props) => {
  return <FormCheck {...props} type="switch" />;
};
const InputRadio = (props) => {
  return <FormCheck {...props} type="radio" />;
};
const FormCheck = (props) => {
  return (
    <Input
      checked={false}
      type="checkbox"
      {...props}
      component={Form.Check}
      valueField="checked"
    />
  );
};

const InputColor = ({ className, label, ...props }) => {
  if (!label) {
    return (
      <Input
        value=""
        {...props}
        className={className}
        type="color"
        component={Form.Control}
      />
    );
  }
  return (
    <div className={className}>
      {label && <Label htmlFor={props.id}>{label}</Label>}
      <Input value="" {...props} type="color" component={Form.Control} />
    </div>
  );
};

const InputDate = ({
  className,
  label,
  max: maxProp,
  min: minProp,
  step: stepProp,
  value: valueProp,
  ...props
}) => {
  const { itemData: data } = props;
  let { value: max } = useSource(maxProp, { data });
  let { value: min } = useSource(minProp, { data });
  let { value: step } = useSource(stepProp, { data });
  let { value } = useSource(valueProp, { data });

  if (max) {
    const maxDate = new Date(max);
    if( !Number.isNaN( maxDate.getTime() ) ){
      max = maxDate.toISOString().replace(/T.*/, '');
    }
   
  }
  if (min) {
    const minDate = new Date(min);
    if( !Number.isNaN( minDate.getTime() ) ){
      min = minDate.toISOString().replace(/T.*/, '');
    }
  }
  if (value) {
    const valueDate = new Date(value);
    if( !Number.isNaN( valueDate.getTime() ) ){
      value = valueDate.toISOString().replace(/T.*/, '');
    }
  }

  if (!label) {
    return (
      <Input
        value={value}
        {...props}
        className={className}
        max={max}
        min={min}
        step={step}
        type="date"
        component={Form.Control}
      />
    );
  }
  return (
    <div className={className}>
      {label && <Label htmlFor={props.id}>{label}</Label>}
      <Input
        value={value}
        {...props}
        max={max}
        min={min}
        type="date"
        component={Form.Control}
      />
    </div>
  );
};

const InputFile = ({ className, label, ...props }) => {
  if (!label) {
    return (
      <Input
        value=""
        {...props}
        className={className}
        type="file"
        component={Form.Control}
        valueField="files"
      />
    );
  }
  return (
    <div className={className}>
      {label && <Label htmlFor={props.id}>{label}</Label>}
      <Input
        value=""
        {...props}
        type="file"
        component={Form.Control}
        valueField="files"
      />
    </div>
  );
};

const InputRange = ({ className, label, ...props }) => {
  if (!label) {
    return (
      <Input
        value={0}
        {...props}
        className={className}
        component={Form.Range}
      />
    );
  }
  return (
    <div className={className}>
      {label && <Label htmlFor={props.id}>{label}</Label>}
      <Input value={0} {...props} component={Form.Range} />
    </div>
  );
};

const InputSelect = ({
  children,
  className,
  dataSrc,
  filter,
  floating,
  label,
  limit,
  mapSrc,
  options,
  order,
  ...props
}) => {
  const { id, itemData: data } = props;
  const { value: sourceData } = useSource(dataSrc, {
    data,
    filter,
    isCollection: true,
    order,
    limit,
  });

  let sourceChildren;
  if (sourceData) {
    const label = mapSrc?.label || 'label';
    const value = mapSrc?.value || 'value';
    sourceChildren = Object.values(sourceData).map((data, index) => (
      <option
        key={`${id}-option-${index}`}
        disabled={!data[value]}
        value={data[value] || '-'}
      >
        {data[label] || ''}
      </option>
    ));
    children = Array.isArray(children) ? children : [children];
    children = [...children, ...sourceChildren];
  }

  if (!label) {
    return (
      <Input
        value=""
        {...props}
        aria-label={props.id}
        className={className}
        component={Form.Select}
      >
        {children}
      </Input>
    );
  }
  const Wrapper = floating ? FloatingLabel : 'div';
  return (
    <Wrapper className={`${className} d-flex flex-column`.trim()} label={label}>
      {label && !floating && <Label htmlFor={props.id}>{label}</Label>}
      <Input value="" {...props} aria-label={label} component={Form.Select}>
        {children}
      </Input>
    </Wrapper>
  );
};

const InputEmail = (props) => {
  return <FormControl {...props} type="email" />;
};
const InputNumber = (props) => {
  return <FormControl {...props} type="number" />;
};
const InputPassword = (props) => {
  return <FormControl {...props} type="password" />;
};
const TextArea = (props) => {
  return <FormControl {...props} as="textarea" />;
};
const FormControl = ({ className = '', floating, label, ...props }) => {
  if (props.as === 'textarea') {
    className = `${className} flex-grow-1`.trim();
  }
  if (!label) {
    return (
      <Input
        type="text"
        value=""
        {...props}
        className={className}
        component={Form.Control}
      />
    );
  }
  const Wrapper = floating ? FloatingLabel : 'div';
  return (
    <Wrapper className={`${className} d-flex flex-column`.trim()} label={label}>
      {label && !floating && <Label htmlFor={props.id}>{label}</Label>}
      <Input
        placeholder=" "
        type="text"
        value=""
        {...props}
        component={Form.Control}
      />
    </Wrapper>
  );
};

export const Input = ({
  component = Form.Control,
  onBlur,
  onChange,
  onKeyDown,
  valueField = 'value',
  src,
  ...mainProps
}) => {
  let { itemData: data, [valueField]: defaultValue, ...props } = mainProps;

  if (typeof defaultValue === 'string') {
    defaultValue = defaultValue?.replace(
      /\(?@field\.([^)]*)\)?/g,
      (str, field) => data[field]
    );
  }
  const changing = useRef(false);
  const { set } = useFunctions();
  const { id } = props;
  let { value: srcValue = defaultValue } = useSource(src, { data });
  if (srcValue === null) {
    srcValue = defaultValue;
  }
  const [value, setValue] = useState(srcValue);
  const inputProps = {
    ...props,
    [valueField]: value !== src ? value : defaultValue,
  };

  if (props.type === 'date' && value) {
    const dateValue = new Date(value);
    if( !Number.isNaN( dateValue.getTime() ) ){
      inputProps.value = new Date(value).toISOString().split('T')[0];
    }
  }

  const handleBlur = async () => {
    onBlur?.({ data });
  };

  const handleChange = (event) => {
    const { currentTarget } = event;
    let { [valueField]: value } = currentTarget;
    const { multiple, type } = props;
    if (type === 'date') {
      value = new Date(value).getTime();
    }
    if (type === 'file' && !multiple) {
      value = value[0];
    }
    value = type === 'number' ? parse(value) : value;

    setValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onKeyDown?.({data})
    }
  };

  useEffect(() => {
    return () => set({ what: `@element.${id}`, value: null });
  }, []);

  useEffect(() => {
    if (value === srcValue || value._equals(srcValue)) {
      return;
    }
    (async () => {
      const elementValue = {
        [valueField]: value,
      };
      if (valueField === 'files') {
        elementValue.url = window.URL.createObjectURL(value);
      }
      await set({
        what: `@element.${id}`,
        value: elementValue,
      });
      if (src) {
        await set({ what: src, value }, { data });
      }
      onChange?.({ data });
    })();
  }, [value]);

  useEffect(() => {
    setValue(srcValue);
  }, [srcValue]);

  useEffect(() => {
    if (!changing.current) {
      return;
    }
    onChange?.({ data });
    changing.current = false;
  }, [changing, srcValue]);

  return (
    <Component
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      {...inputProps}
      component={component}
    />
  );
};

Input.Checkbox = InputCheckbox;
Input.Color = InputColor;
Input.Date = InputDate;
Input.Email = InputEmail;
Input.File = InputFile;
Input.Number = InputNumber;
Input.Password = InputPassword;
Input.Radio = InputRadio;
Input.Range = InputRange;
Input.Select = InputSelect;
Input.Switch = InputSwitch;
Input.Text = FormControl;
Input.TextArea = TextArea;

Input.displayName = 'Input';
export default Input;
