import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ContextProviders } from './context';


// Custom imports:
import Dialogs from './dialogs';
import { AdminView, Admin_homeView, Auth_destinationView, Auth_logoutView, OauthView, PrivacyView, RemoveView, TokenView } from './pages';
import PrivateRoute from './PrivateRoute';


import './styles/main.scss';

function App() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
          <title>{t('title')}</title>
          <meta name="description" content={t('description')} data-react-helmet="true" />
      </Helmet>
      <Router>
        <ContextProviders>
          <Switch>
            <Route exact path="/" component={OauthView} />
						<Route exact path="/admin" component={AdminView} />
<Route exact path="/admin/:params(.+)" component={AdminView} />
						<PrivateRoute exact path="/admin_home" component={Admin_homeView} />
						<PrivateRoute exact path="/admin_home/:params(.+)" component={Admin_homeView} />
						<Route exact path="/auth_destination" component={Auth_destinationView} />
						<Route exact path="/auth_destination/:params(.+)" component={Auth_destinationView} />
						<Route exact path="/auth_logout" component={Auth_logoutView} />
						<Route exact path="/auth_logout/:params(.+)" component={Auth_logoutView} />
						<Route exact path="/oauth" component={OauthView} />
						<Route exact path="/oauth/:params(.+)" component={OauthView} />
						<Route exact path="/privacy" component={PrivacyView} />
						<Route exact path="/privacy/:params(.+)" component={PrivacyView} />
						<Route exact path="/remove" component={RemoveView} />
						<Route exact path="/remove/:params(.+)" component={RemoveView} />
						<Route exact path="/token" component={TokenView} />
						<Route exact path="/token/:params(.+)" component={TokenView} />
						<Route exact path="*" component={OauthView} />
						
          </Switch>

					<Dialogs />
        </ContextProviders>
      </Router>
    </>
  );
}

export default App;
