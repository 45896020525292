import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Auth_fullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Button } from '../components';
import { useTranslation } from 'react-i18next';

export const Auth_destinationView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const authLogoutButtonOnClick = (options) => {
		run([{"function":"goto","view":"auth_logout"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('auth_destination.title')}</title>
      </Helmet>
      <Auth_fullscreenWrapper>
          
			<Group
				id="logoutBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative" >
				<Button
					id="authLogoutButton"
					onClick={authLogoutButtonOnClick}
					variant="primary"
					className="w-auto h-auto align-center valign-center position-absolute" >
					{t('auth_destination.authLogoutButton')}
				</Button>
			</Group>
      </Auth_fullscreenWrapper>
    </>
  );
};
