import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Auth_fullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Lottie } from '../components';
import auth_progress from '../assets/animations/auth_progress.json';
import { useTranslation } from 'react-i18next';

export const TokenView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:

  return (
    <>
      <Helmet>
				<title>{t('token.title')}</title>
      </Helmet>
      <Auth_fullscreenWrapper>
          
			<Group
				id="tokenCurtain"
				direction="vertical"
				className="w-100 min-h-100vh h-100vh position-absolute bg-oauth_lighten" >
				<Lottie
					id="tokenLottie"
					src={auth_progress}
					className="w-150px min-h-150px h-150px align-center valign-center position-absolute" />
			</Group>
      </Auth_fullscreenWrapper>
    </>
  );
};
