import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const InstructionsDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const instructions_buttons_positiveOnClick = (options) => {
		run([{"function":"hide","dialog":"instructions"}], options)
	}

  return (
    
    
			<Modal id="instructions" centered>
					<Modal.Header closeButton>
						<Modal.Title>{t('instructions.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Group
							id="instructionsGroup1672245845886"
							visible={false}
							if={{"what":"@property.client_id","is":"idemoappalexa"}}
							direction="vertical"
							className="w-100 h-auto position-relative mt-32px" >
							<Text
								id="instructionsTitle1672245845886"
								tag="b"
								className="w-auto h-auto align-center valign-center position-relative text-primary-text ff-auth_bold" >
								{t('instructions.instructionsTitle1672245845886')}
							</Text>
							<Text
								id="instructionsContent1672245845886"
								className="w-auto h-auto align-center valign-center position-relative text-secondary-text ff-auth_bold text-center" >
								{t('instructions.instructionsContent1672245845886')}
							</Text>
						</Group>
						<Group
							id="instructionsGroup1672245875475"
							visible={false}
							if={{"what":"@property.client_id","is":"idehomeactions"}}
							direction="vertical"
							className="w-100 h-auto position-relative mt-32px" >
							<Text
								id="instructionsTitle1672245875475"
								tag="b"
								className="w-100 h-auto align-center valign-center position-relative text-primary-text ff-auth_bold text-start" >
								{t('instructions.instructionsTitle1672245875475')}
							</Text>
							<Text
								id="instructionsContent1672245875475"
								className="w-100 h-auto align-center valign-center position-relative text-secondary-text ff-auth_bold text-start" >
								{t('instructions.instructionsContent1672245875475')}
							</Text>
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="instructions_buttons_positive"
							onClick={instructions_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('instructions.instructions_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default InstructionsDialog;
