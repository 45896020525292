import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const ConfirmationDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const confirmation_buttons_positiveOnClick = (options) => {
		run([{"id":"do1681292642099","function":"do","if":{"what":"@property.action","is":"unlink_hub"},"what":[{"id":"set1681292680774","function":"set","what":"@firebase.firestore.hubs.(@property.hubID).family_id","value":null},{"id":"set1681292711509","function":"set","what":"@database.hubs.(@property.hubID).family_id","value":null}]},{"function":"hide","dialog":"confirmation"}], options)
	}
	const confirmation_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"confirmation"}], options)
	}

  return (
    
    
			<Modal id="confirmation">
					<Modal.Body>
						<Text
							id="unlinkHub"
							if={{"what":"@property.action","is":"unlink_hub"}}
							className="w-100 h-auto position-relative" >
							{t('confirmation.unlinkHub')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="confirmation_buttons_negative"
							onClick={confirmation_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('confirmation.confirmation_buttons_negative')}
						</Button>
						<Button
							id="confirmation_buttons_positive"
							onClick={confirmation_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('confirmation.confirmation_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default ConfirmationDialog;
