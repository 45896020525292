import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Auth_fullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text } from '../components';
import { useEffect } from 'react';
import icon_192 from '../assets/images/icon_192.png';
import { useTranslation } from 'react-i18next';

export const RemoveView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const removeParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( removeParams, [])
	const removeOnLoaded = (options) => {
		run([{"id":"wait1701900247537","function":"wait","seconds":2},{"id":"set1701900247537","function":"set","what":"@cookie.lang","value":"@property.lang"},{"id":"set1701900247537","function":"set","what":"@property.iso","value":"en"},{"id":"set1701900247537","function":"set","what":"@property.iso","value":"es","if":{"what":"@cookie.lang","is":"es"}},{"id":"set1701900247537","function":"set","what":"@property.iso","value":"ca","if":{"what":"@cookie.lang","is":"ca"}},{"id":"set1701900247537","function":"set","what":"@property.iso","value":"fr","if":{"what":"@cookie.lang","is":"fr"}},{"id":"set1701900247537","function":"set","what":"@property.iso","value":"pt","if":{"what":"@cookie.lang","is":"pt"}},{"id":"set1701900247537","function":"set","what":"@property.iso","value":"it","if":{"what":"@cookie.lang","is":"it"}},{"id":"set1701900247537","function":"set","what":"@app.language","value":"@property.iso"}], options)
	}
	useEffect(removeOnLoaded, [])

  return (
    <>
      <Helmet>
				<title>{t('remove.title')}</title>
      </Helmet>
      <Auth_fullscreenWrapper>
          
			<Group
				id="privacyBody1701900247537"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative p-24px" >
				<Group
					id="privacyWrapper1701900247537"
					direction="vertical"
					className="max-w-1000px w-100 h-auto align-center position-relative p-32px mt-24px" >
					<Image
						id="policyLogo1701900247537"
						src={icon_192}
						className="w-156px h-auto border-radius-24px align-center position-relative" />
					<Text
						id="policyTitle1701900247537"
						tag="h2"
						className="w-auto h-auto align-center valign-center position-relative mt-16px text-primary-dark ff-auth_bold" >
						{t('remove.policyTitle1701900247537')}
					</Text>
					<Text
						id="policySubtitle1701900247537"
						tag="h1"
						className="w-auto h-auto align-center valign-center position-relative mt-16px text-primary ff-auth_bold" >
						{t('remove.policySubtitle1701900247537')}
					</Text>
					<Group
						id="policyContentWrapper1701900247537"
						direction="vertical"
						className="w-100 h-auto position-relative mt-48px" >
						<Text
							id="signinTitle1701900247537"
							tag="h3"
							className="w-auto h-auto align-center valign-center position-relative mt-16px text-primary-text ff-gantariregular" >
							{t('remove.signinTitle1701900247537')}
						</Text>
					</Group>
				</Group>
			</Group>
      </Auth_fullscreenWrapper>
    </>
  );
};
